import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { useLang } from '../../context';
import { getUrlPrefix } from '../../translations';
import * as styles from './ViewMoreArticles.module.scss';

const ViewMoreArticles = ({ posts, text }) => {
  const lang = useLang();

  if(lang !== 'pl') {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.sectionTitle}>{text}</div>
      {
        posts.map(
          ({ node: { frontmatter: { title, slug, featuredImage } } }) => (
            <Link key={slug} to={`/${getUrlPrefix(lang)}${slug}`}>
              <div className={styles.post}>
                <div className={styles.title}>{ title }</div>
                <Img className={styles.cover} fluid={featuredImage.childImageSharp.fluid} />
              </div>
            </Link>
          )
        )
      }
    </div>
  );
};

export default ViewMoreArticles;
