import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Disqus } from "gatsby-plugin-disqus"
import { Helmet } from "react-helmet"
import { translate, getUrlPrefix } from "../translations"
import Layout from "../components/Layout"
import CoverWithTitle from "../components/CoverWithTitle"
import Seo from "../components/seo"
import PublishedOn from "../components/PublishedOn"
import ViewMoreArticles from "../components/ViewMoreArticles"
import { LangContext } from "../context"
import * as styles from "./blog.module.scss"

const getBlogPostSchema = ({
  title: name,
  date: datePublished,
  image,
  url,
  lang,
}) => ({
  "@context": "http://schema.org",
  "@type": "Article",
  author: {
    "@type": "Person",
    name: "Damian Sowa",
  },
  copyrightHolder: {
    "@type": "Person",
    name: "Damian Sowa",
  },
  copyrightYear: "2019",
  creator: {
    "@type": "Person",
    name: "Damian Sowa",
  },
  datePublished,
  headline: name,
  inLanguage: lang === "pl" ? "pl-PL" : "en-GB",
  url: url,
  name,
  image: {
    "@type": "ImageObject",
    url: image,
  },
  mainEntityOfPage: url,
})

export const BlogPostTemplate = ({
  title,
  featuredImage,
  featuredImageSrc,
  content,
  date,
  html,
  isPreview,
}) => {
  useEffect(() => {
    if (window && window.instgrm) {
      window.instgrm.Embeds.process()
    }
  })

  return (
    <>
      <CoverWithTitle
        title={title}
        featuredImage={featuredImage}
        featuredImageSrc={featuredImageSrc}
        isPreview={isPreview}
        isFullPost
      />
      {!isPreview && (
        <div
          className={`prose ${styles.content}`}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}
      {isPreview && <div className={`prose ${styles.content}`}>{content}</div>}
      <div className={styles.tagsAndDate}>
        <div className={styles.publishedOn}>
          <PublishedOn date={date} />
        </div>
      </div>
    </>
  )
}

const Template = ({
  data, // this prop will be injected by the GraphQL query below.
  pageContext: { lang },
}) => {
  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, id } = markdownRemark

  const { title, featuredImage, slug, date } = frontmatter

  const description = html.replace(/<[^>]*>?/gm, "").substring(0, 200) + "..."

  const disqusConfig = {
    url: `https://damiansowa.pl${getUrlPrefix(lang)}/${slug}`,
    identifier: id,
    title: title,
    language: lang,
  }

  const posts = allMarkdownRemark.edges

  return (
    <LangContext.Provider value={lang}>
      <Layout>
        <Seo title={title} description={description} />

        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(
              getBlogPostSchema({
                title,
                date,
                url: `https://damiansowa.pl/${slug}`,
                image: `https://damiansowa.pl/${featuredImage.publicURL}`,
                lang,
              })
            )}
          </script>
          <script async src="//www.instagram.com/embed.js" />
        </Helmet>

        <BlogPostTemplate
          title={title}
          featuredImage={featuredImage}
          html={html}
          date={date}
        />
        <ViewMoreArticles
          posts={posts}
          text={translate(lang, "post.checkAlso")}
        />
        <Disqus config={disqusConfig} />
      </Layout>
    </LangContext.Provider>
  )
}

export default Template

export const pageQuery = graphql`
  query ($slug: String!, $lang: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      id
      frontmatter {
        date
        slug
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
      filter: { frontmatter: { type: { eq: "blogPost" }, lang: { eq: $lang } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
`
